@import '../../styles/mixins/flex';
@import '../../styles/mixins/dimensions';

.dashbord-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dashbord-btn {
  margin-top: 30px;
}

.feature-section {
  @include justify-content-between;
}

.dashbord-img {
  @include justify-content-between;
}

.first-img {
  margin-bottom: 120px !important;
}
.secend-img {
  margin-bottom: 120px !important;
}

.dashbord-img .first-img img,
.dashbord-img .secend-img img {
  margin-top: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain !important;
}
.end-secend {
  margin-top: 50px;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .dashbord-img .secend-img img {
    margin-top: 30px;
  }
}

// ====

.reports h4 span {
  font-size: 24px !important;
  line-height: 28px !important;
}
