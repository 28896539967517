@import "./variables/colors";
@import "./variables/typography";
@import "./variables/margin";
@import "./mixins/flex";
@import "./mixins/fonts";
@import "./mixins/dimensions";
@import "./mixins/typography-mixin";
@import "./mixins/position";
@import "./mixins/spacing";
.txtcntr {
  text-align: center;
}

:root {
  --Colour-1: $color-white;
  --Colour-2: $color-black;
  --Colour-3: $color-gray;
  --Colour-4: $color-light-gray;
  --Colour-5: $color-red;
  --Colour-6: $color-green;
  --Colour-7: $color-purple;
  --Colour-8: $color-dark-purple;
  --Colour-9: $color-light-green;
  --Heading-95: $heading-95;
  --Heading-75: $heading-75;
  --Font-55: $font-55;
  --Font-50: $font-50;
  --Font-45: $font-45;
  --Font-35: $font-35;
  --Font-30: $font-30;
  --Font-20: $font-20;
  --Font-15: $font-15;
  --Margin-sections: $margin-sections;
  --Margin-headers: $margin-headers;
}

@font-face {
  font-family: AvertaBold;
  src: url("../Assets//Font/Averta\ Bold.otf");
}

@font-face {
  font-family: Averta;
  src: url("../Assets/Font/Averta\ Light.otf");
}

@font-face {
  font-family: AvertaSemi;
  src: url("../Assets/Font/Averta\ Semibold.otf");
}

@font-face {
  font-family: "Averta CY";
  src: url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Light.woff2)
      format("woff2"),
    url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Light.woff) format("woff"),
    url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Averta CY";
  src: url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Regular.woff2)
      format("woff2"),
    url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Regular.woff) format("woff"),
    url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Regular.ttf)
      format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Averta CY";
  src: url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Semibold.woff2)
      format("woff2"),
    url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Semibold.woff) format("woff"),
    url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Semibold.ttf)
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Averta CY";
  src: url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Bold.woff2)
      format("woff2"),
    url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Bold.woff) format("woff"),
    url(//cdn.cuberto.com/cb/fonts/averta/AvertaCY-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.light-font {
  @include light-font;
}

.semi-font {
  @include semi-font;
}

.bold-font {
  @include bold-font;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Averta CY";
}

.App {
  width: 100%;
  height: 100%;
}

.container {
  @include container;
}

.text-center {
  @include text-center;
}

.underline {
  @include underline;
}

.mt-section {
  // @include mt-section;

  margin-left: auto;
  margin-right: auto;
}
.mt-header-parent {
  display: flex;
  justify-content: center;
}
/*Parent Container for all Page */
.parent-container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 1700px) {
    width: 85%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.mt-header.home-agency-page {
  @media only screen and (min-width: 1400px) {
  }
  a.fs-95 {
    @media only screen and (max-width: 1200px) {
      font-size: 65px !important;
    }
  }
}
.mt-header {
  @include mt-header;
  margin: 0;
  font-family: "Averta CY", sans-serif;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;

  @media only screen and (max-width: 1440px) {
    height: 400px;
    justify-content: flex-end;
    margin-bottom: 40px;
  }
  div.d-flex.flex-column-tablet {
    @media only screen and (max-width: 992px) {
      a {
        margin-bottom: 5px !important;
      }
    }
    @media only screen and (max-width: 900px) {
      a {
        line-height: 61px !important;
        font-size: 50px !important;
        margin-left: 0px !important;
        margin-right: 15px !important;
        margin-bottom: 15px !important;
      }
    }
    @media only screen and (max-width: 600px) {
      a {
        font-size: 50px !important;
      }
    }
  }
  &.faq {
    @media only screen and (max-width: 768px) {
      justify-content: center;
    }
  }
}

.fs-95 {
  @include fs-95;
  font-family: "Averta CY", sans-serif;
}

.fs-55 {
  @include fs-55;
}

.fs-30 {
  @include fs-30;
  font-family: "Averta CY";
  font-weight: 300;
}

.fs-45 {
  @include fs-45;
  font-family: "Averta CY";
}

.fs-75 {
  @include fs-75;
  font-family: "Averta CY";
}

.w-80 {
  @include w-80;
}

.w-70 {
  @include w-70;
}

.h-100 {
  @include h-100;
}

.gray-color {
  color: var(--Colour-3);
}

.fw-semibold {
  @include fw-semibold;
}

.slider-arrow {
  @include slider-arrow;
}

.slider-arrow-left {
  @include slider-arrow-left;
}

.slider-arrow-right {
  /* margin-right: 100px; */
  cursor: pointer;
}

.stroked {
  @include stroked;
}

.overview-content {
  @include overview-content;
}

.mt-1 {
  @include mt-1;
}

.mt-6 {
  @include mt-6;
}

.d-flex {
  @include d-flex;
}

.flex-column {
  @include flex-column;
}

.flex-wrap {
  @include flex-wrap;
}

.flex-direction-column {
  @include flex-direction-column;
}

.justify-content-between {
  @include justify-content-between;
}

.justify-content-center {
  @include justify-content-center;
}

.align-items-center {
  @include align-items-center;
}

.align-items-end {
  @include align-items-end;
}

.my-4 {
  @include my-4;
}

.fw-bold {
  @include fw-bold;
}

.w-100 {
  @include w-100;
}

.AccordionSummary {
  @include accordion-summary;
}

a {
  text-decoration: none;
}

.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: var(--color-text);
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: "";
}

.link--metis {
  font-family: bely-display, sans-serif;
  text-transform: lowercase;
}

.link--metis::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.link--metis:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

.cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.1;
  transform: translate(-50%, -50%);
  mix-blend-mode: difference;
}

.link--metis {
  font-size: 35px;
  margin-bottom: 20px;
}

ul li:hover ~ .cursor {
  transform: scale(6);
}

.slick-slider {
  width: 90%;
  margin: 0 auto;
}

.outlined {
  color: white !important;
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000 !important;
}

.dark-mode .outlined {
  color: #000 !important;
  text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff,
    1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff, -1px 0 0 #fff !important;
}
@media only screen and (min-width: 1000px) {
  .dark-mode .stroked {
    color: white;
    -webkit-text-stroke: 1px black;
  }
}

@media only screen and (max-width: 900px) {
  .fs-95 {
    font-size: 60px !important;
    line-height: 60px !important;
  }

  .fs-75 {
    font-size: 50px;
  }

  .fs-75 {
    font-size: 50px;
  }

  .w-70 {
    width: 100% !important;
  }

  .overview-content {
    width: 100%;
    margin-top: 20px !important;
  }

  .fs-45 {
    font-size: 21px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 600px) {
  .footer-whatsapp {
    margin-top: 30px;
  }

  .fs-45 {
    font-size: 16px;
    line-height: 30px;
  }

  .fs-95 {
    font-size: 40px !important;
    line-height: 45px !important;
  }

  .fs-30 {
    font-size: 25px;
    line-height: 30px;
  }

  .fs-75 {
    font-size: 30px !important;
  }

  .accordion-text {
    font-size: 20px !important;
  }

  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 0 !important;
  }

  .icon-mobile {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 400px) {
  .fs-45 {
    font-size: 14px;
    line-height: 25px;
  }

  .fs-95 {
    font-size: 30px !important;
    line-height: 45px;
  }

  .filter-item:nth-child(2) {
    margin: 20px 0;
  }

  .card-title {
    font-size: 20px !important;
  }

  .flex-column-mobile {
    flex-direction: column;
  }
}

.slick-prev.slick-disabled:before {
  display: none;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 auto !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  height: 100px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  width: 80%;
  background-color: transparent !important;
}

.dark-mode .css-i4bv87-MuiSvgIcon-root {
  color: rgba(131, 130, 130, 1);
}

.light-mode .css-i4bv87-MuiSvgIcon-root {
  color: #000;
}

.Accordion-wrapper {
  padding: 0 !important;
  box-shadow: none !important;
}

.hero-img-container {
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
}

.hero-img {
  width: 100%;
}

/* slider */

.slick-next,
.slick-prev {
  top: 100%;
}

.slick-next {
  right: 0;
}

.slick-dots li button:before {
  font-size: 10px;
}

.dark-mode .slick-dots li button:before {
  color: #fff;
}

.slick-next {
  display: none !important;
}

/* pricing page  */
.pricing-list {
  font-weight: bold;
  margin-left: 30px;
}

.pricing-list p {
  color: #787878;
  margin: 0 !important;
}
rect[Attributes] {
  display: none !important;
}

.w-35 {
  width: 30%;
}

/*Common Main Header for 4 pages */
.mt-header.common-mt-header {
  @media only screen and (max-width: 1440px) {
    justify-content: center;
    margin-bottom: 0px;
    height: 70vh;
  }
}

.common-main-header {
  h1.fs-75 {
    line-height: 75px !important;
    @media only screen and (max-width: 1050px) {
      font-size: 60px !important;
      margin-top: 15px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 50px !important;
      line-height: 50px !important;
    }
    @media only screen and (max-width: 768px) {
      font-size: 45px !important;
      line-height: 45px !important;
    }
    @media only screen and (max-width: 545px) {
      font-size: 35px !important;
      line-height: 40px !important;
    }
    @media only screen and (max-width: 376px) {
      font-size: 29px !important;
      line-height: 36px !important;
    }
  }
}
.common-main-header {
  @media only screen and (max-width: 768px) {
    width: 90%;
    h1.fs-75 {
      font-weight: 600 !important;
    }
  }
}
.common-mt-section {
  .fs-30 {
    font-size: 28px !important;
    @media only screen and (max-width: 768px) {
      font-size: 20px !important;
      line-height: 25px !important;
      width: 85%;
      max-width: 100%;
    }
  }
  padding: 150px 0;
  .fs-30.text-slide-up-animation-3 {
    overflow-y: visible;
    @media only screen and (min-width: 1921px) {
      line-height: normal;
    }
    font-weight: 300;
    line-height: 32px;
  }
  @media only screen and (max-width: 768px) {
    width: 85%;
    .fs-30.text-slide-up-animation-3 {
      font-weight: 300;
      // line-height: 23px;
    }
  }
  .dashbord-btn {
    @media only screen and (max-width: 500px) {
      width: 100%;
      .btn-component {
        font-size: 12px;
      }
    }
  }
}
.feature-section-common {
  width: 80%;
  padding: 200px 0;
  margin-bottom: 100px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
  .overview-content {
    line-height: normal;
    font-size: 28px !important;
    @media only screen and (max-width: 768px) {
      font-size: 20px !important;
      line-height: 26px !important;
      width: 85%;
      max-width: 100%;
      margin-left: 0;
    }
  }
  h4 {
    color: rgba(#000, 0.9);
    font-weight: 600;
    font-size: 30px;
    span {
      font-weight: 600;
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 800px) {
    width: 85%;
    flex-direction: column;
    padding: 100px 0;
  }

  .text-slide-up-animation-wrapper-3 {
    font-weight: 300 !important;
    line-height: 33px;
    @media only screen and (max-width: 768px) {
      line-height: 25px;
    }

    @media only screen and (max-width: 768px) {
      font-weight: 300 !important;
    }
  }
  h4.text-slide-up-animation-wrapper-3 {
    font-weight: 600;
  }
}

.end-section-common {
  padding: 120px 0;
  margin-top: 100px;
  h2.fs-95 {
    font-weight: 600 !important;
  }
  @media only screen and (max-width: 768px) {
    width: 85%;
  }
  h2 {
    padding: 40px 0;
  }
  p {
    font-size: 28px !important;
    line-height: 32px !important;
    font-weight: 300;
    max-width: 850px;

    @media only screen and (max-width: 768px) {
      font-size: 22px !important;
      line-height: 26px !important;
      font-weight: 300;
    }
  }
}
.dark-mode {
  .home-agency-page {
    a {
      &::after {
        background-color: #fff !important;
      }
    }
    span.text-slide-up-animation-text-1 {
      color: #000;
      text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff,
        1px 0 0 #fff, 1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff,
        -1px 0 0 #fff;
    }
  }
}
.home-agency-page {
  .flex-column-tablet {
    a {
      font-weight: 400 !important;
    }
  }
  span.text-slide-up-animation-text-1 {
    color: #000;
  }
  @media only screen and (max-width: 1199px) {
    .flex-column-tablet {
      flex-direction: column;
      a {
        font-weight: 400 !important;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 6px;
          width: 100%;
          height: 1px;
          background-color: #000;
        }
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    .flex-column-tablet {
      a {
        font-weight: 600 !important;
        font-size: 65px !important;
      }
    }
  }
}

/*DashBoard Images */
.dashbord-img {
  justify-content: center !important;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
  > div {
    width: 42%;
    margin: 0;
    &.first-img {
      transform: translateY(-100px);
      @media only screen and (max-width: 768px) {
        transform: translateY(0px);
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    span {
      display: block !important;
      width: 100% !important;
      height: 100%;
      span span span span span {
        &.img-slide-up-animation-img-1 {
          @media only screen and (max-width: 1920px) {
            position: relative;
            padding-bottom: 100%;
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100% !important;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      margin-left: 0px;
      @media only screen and (max-width: 1000px) {
        margin-left: 20px;
      }
      @media only screen and (max-width: 768px) {
        margin-left: 0px;
      }
    }
  }
  &.fundalysis-image {
    & > div:nth-child(2) {
      margin-left: 80px !important;
      @media only screen and (max-width: 1000px) {
        margin-left: 40px !important;
      }
      @media only screen and (max-width: 768px) {
        margin-left: 0px !important;
      }
    }
  }
  img {
    width: 100%;
    max-width: 100% !important;
    height: 67%;
    @media only screen and (max-width: 768px) {
      margin-top: 20px !important;
    }
  }
}
.dashbord-img {
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}
.dashbord-img ~ div.dashbord-img {
  margin-bottom: 0;

  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 0;
  }
}
.common-txt-300 {
  font-weight: 400;
  line-height: 23px;
  font-size: 20px !important;
  @media only screen and (max-width: 768px) {
    font-weight: 300;
    line-height: 23px;
    margin-top: 150px;
  }

  > span:nth-child(50) {
    margin-top: 20px;
  }
}

.mt-15 {
  margin-top: 15px;
}
.Overview-Categories {
  padding-bottom: 150px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  > p {
    &:nth-child(1) {
      font-family: "Averta CY";
      font-weight: 600;
      font-size: 30px;
    }
  }
  .overview-content {
    font-family: "Averta CY";
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    margin-top: 5px !important;
    > span {
      &:last-child {
        display: block;
        font-weight: 600;
      }
    }
  }
}
.faq-dropdown {
  .MuiAccordionSummary {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .AccordionDetails {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-weight: 300 !important;
  }
  > div {
    width: 100%;
  }
  h5 {
    font-size: 20px !important;
  }
  .AccordionDetails {
    @media only screen and (max-width: 500px) {
      padding: 0 !important;
      font-weight: 300 !important;
      line-height: 23px !important;
      font-size: 20px !important;
    }
  }
}
.dark-mode {
  .feature-section-common h4 {
    color: #fff;
  }
}

.home-agency-page span.text-slide-up-animation-text-1 {
  color: white;
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
}
