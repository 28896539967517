.container-nav {
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
}

li {
  cursor: pointer;
}

.first-list {
  width: 15%;
}
.logo {
  font-family: Averta CY !important;
  font-size: 22px !important;
}
.container-nav {
  margin: 0 auto;
  padding-top: 30px;
  padding-top: 60px !important;
  width: 90%;
}
.mobile-icon-menu {
  display: none;
}

.light-mode .logo {
  color: var(--Colour-2);
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;

  z-index: 100000;
  cursor: pointer;
}
.dark-mode .logo {
  color: var(--Colour-1);
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;

  z-index: 100000;
  cursor: pointer;
}

.icons {
  @media (hover: hover) {
    .nav-link-wrapper:hover .nav-link-hover-wrapper {
      transform: translateY(-105%) !important;
      @media only screen and (max-width: 1199px) {
        transform: translateY(0px) !important;
      }
    }
  }
  cursor: pointer;
  font-size: 18px;
  justify-content: flex-end;
  margin-right: 50px;
  position: relative;
  text-transform: capitalize;
  width: auto;
  width: 220px;
  > div {
    min-width: 60px;
    > a {
      width: 100%;
    }
  }
  .spanSvg {
    display: block;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(0.75);
  }

  > div {
    > div.icon.user-icon {
      //  display: none;
    }
  }
  .search-icon {
    .spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30px' height='30px'%3E%3Cpath d='M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z'/%3E%3C/svg%3E");
    }
    .magnetic-area:hover + #myspan span.spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30px' height='30px'%3E%3Cpath d='M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z'/%3E%3C/svg%3E");
    }
  }
  .user-icon {
    .spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg width='75' height='75' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M37.5 5.35714C29.3637 5.35714 22.7679 11.9529 22.7679 20.0893C22.7679 28.2256 29.3637 34.8214 37.5 34.8214C45.6363 34.8214 52.2321 28.2256 52.2321 20.0893C52.2321 11.9529 45.6363 5.35714 37.5 5.35714ZM17.4107 20.0893C17.4107 8.99428 26.405 0 37.5 0C48.595 0 57.5893 8.99428 57.5893 20.0893C57.5893 31.1843 48.595 40.1786 37.5 40.1786C26.405 40.1786 17.4107 31.1843 17.4107 20.0893Z' fill='%23000'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.4315 52.6859C21.84 48.011 29.5676 45.492 37.5 45.492C45.4324 45.492 53.16 48.011 59.5685 52.6859C65.977 57.3607 70.7354 63.9499 73.1578 71.5035C73.419 72.3182 73.2754 73.2088 72.7711 73.9C72.2669 74.5912 71.4628 75 70.6071 75H4.39286C3.53724 75 2.73311 74.5912 2.22887 73.9C1.72462 73.2088 1.58095 72.3182 1.84223 71.5035C4.26455 63.9499 9.02298 57.3607 15.4315 52.6859ZM37.5 50.8491C30.7024 50.8491 24.0804 53.0078 18.5887 57.0139C14.1111 60.2801 10.5736 64.6368 8.29466 69.6428H66.7053C64.4264 64.6368 60.8888 60.2801 56.4113 57.0139C50.9196 53.0078 44.2976 50.8491 37.5 50.8491Z' fill='%23000'/%3E%3C/svg%3E%0A");
    }
    .magnetic-area:hover + #myspan span.spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.7559 17.7625C19.2401 16.5407 18.4916 15.4309 17.552 14.4949C16.6153 13.5562 15.5057 12.8078 14.2844 12.291C14.2735 12.2855 14.2626 12.2828 14.2516 12.2773C15.9551 11.0469 17.0626 9.04258 17.0626 6.78125C17.0626 3.03516 14.0274 0 10.2813 0C6.53523 0 3.50007 3.03516 3.50007 6.78125C3.50007 9.04258 4.60749 11.0469 6.31101 12.2801C6.30007 12.2855 6.28913 12.2883 6.27819 12.2937C5.05319 12.8105 3.95398 13.5516 3.01062 14.4977C2.07193 15.4344 1.32352 16.544 0.806709 17.7652C0.298996 18.9608 0.0251752 20.2427 6.83758e-05 21.5414C-0.000661443 21.5706 0.00445738 21.5996 0.0151234 21.6268C0.0257894 21.654 0.0417867 21.6787 0.0621726 21.6996C0.0825586 21.7205 0.106921 21.7371 0.133824 21.7485C0.160726 21.7598 0.189626 21.7656 0.218818 21.7656H1.85944C1.97976 21.7656 2.07546 21.6699 2.07819 21.5523C2.13288 19.4414 2.98054 17.4645 4.47898 15.966C6.02937 14.4156 8.08835 13.5625 10.2813 13.5625C12.4743 13.5625 14.5333 14.4156 16.0837 15.966C17.5821 17.4645 18.4298 19.4414 18.4844 21.5523C18.4872 21.6727 18.5829 21.7656 18.7032 21.7656H20.3438C20.373 21.7656 20.4019 21.7598 20.4288 21.7485C20.4557 21.7371 20.4801 21.7205 20.5005 21.6996C20.5209 21.6787 20.5369 21.654 20.5475 21.6268C20.5582 21.5996 20.5633 21.5706 20.5626 21.5414C20.5352 20.2344 20.2645 18.9629 19.7559 17.7625ZM10.2813 11.4844C9.02624 11.4844 7.84499 10.9949 6.95632 10.1062C6.06765 9.21758 5.57819 8.03633 5.57819 6.78125C5.57819 5.52617 6.06765 4.34492 6.95632 3.45625C7.84499 2.56758 9.02624 2.07812 10.2813 2.07812C11.5364 2.07812 12.7176 2.56758 13.6063 3.45625C14.495 4.34492 14.9844 5.52617 14.9844 6.78125C14.9844 8.03633 14.495 9.21758 13.6063 10.1062C12.7176 10.9949 11.5364 11.4844 10.2813 11.4844Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    }
  }
}

.dark-mode .icons {
  color: var(--Colour-1);
  .user-icon {
    .spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg width='75' height='75' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M37.5 5.35714C29.3637 5.35714 22.7679 11.9529 22.7679 20.0893C22.7679 28.2256 29.3637 34.8214 37.5 34.8214C45.6363 34.8214 52.2321 28.2256 52.2321 20.0893C52.2321 11.9529 45.6363 5.35714 37.5 5.35714ZM17.4107 20.0893C17.4107 8.99428 26.405 0 37.5 0C48.595 0 57.5893 8.99428 57.5893 20.0893C57.5893 31.1843 48.595 40.1786 37.5 40.1786C26.405 40.1786 17.4107 31.1843 17.4107 20.0893Z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.4315 52.6859C21.84 48.011 29.5676 45.492 37.5 45.492C45.4324 45.492 53.16 48.011 59.5685 52.6859C65.977 57.3607 70.7354 63.9499 73.1578 71.5035C73.419 72.3182 73.2754 73.2088 72.7711 73.9C72.2669 74.5912 71.4628 75 70.6071 75H4.39286C3.53724 75 2.73311 74.5912 2.22887 73.9C1.72462 73.2088 1.58095 72.3182 1.84223 71.5035C4.26455 63.9499 9.02298 57.3607 15.4315 52.6859ZM37.5 50.8491C30.7024 50.8491 24.0804 53.0078 18.5887 57.0139C14.1111 60.2801 10.5736 64.6368 8.29466 69.6428H66.7053C64.4264 64.6368 60.8888 60.2801 56.4113 57.0139C50.9196 53.0078 44.2976 50.8491 37.5 50.8491Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    }
    .magnetic-area:hover + #myspan span.spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg width='75' height='75' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M37.5 5.35714C29.3637 5.35714 22.7679 11.9529 22.7679 20.0893C22.7679 28.2256 29.3637 34.8214 37.5 34.8214C45.6363 34.8214 52.2321 28.2256 52.2321 20.0893C52.2321 11.9529 45.6363 5.35714 37.5 5.35714ZM17.4107 20.0893C17.4107 8.99428 26.405 0 37.5 0C48.595 0 57.5893 8.99428 57.5893 20.0893C57.5893 31.1843 48.595 40.1786 37.5 40.1786C26.405 40.1786 17.4107 31.1843 17.4107 20.0893Z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.4315 52.6859C21.84 48.011 29.5676 45.492 37.5 45.492C45.4324 45.492 53.16 48.011 59.5685 52.6859C65.977 57.3607 70.7354 63.9499 73.1578 71.5035C73.419 72.3182 73.2754 73.2088 72.7711 73.9C72.2669 74.5912 71.4628 75 70.6071 75H4.39286C3.53724 75 2.73311 74.5912 2.22887 73.9C1.72462 73.2088 1.58095 72.3182 1.84223 71.5035C4.26455 63.9499 9.02298 57.3607 15.4315 52.6859ZM37.5 50.8491C30.7024 50.8491 24.0804 53.0078 18.5887 57.0139C14.1111 60.2801 10.5736 64.6368 8.29466 69.6428H66.7053C64.4264 64.6368 60.8888 60.2801 56.4113 57.0139C50.9196 53.0078 44.2976 50.8491 37.5 50.8491Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    }
  }
  .search-icon {
    .spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30px' height='30px'%3E%3Cpath d='M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z' fill='%23fff'/%3E%3C/svg%3E");
    }
    .magnetic-area:hover + #myspan span.spanSvg {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30px' height='30px'%3E%3Cpath d='M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z' fill='%23fff'/%3E%3C/svg%3E");
    }
  }
}

.Social-item a {
  color: var(--Colour-2);
}

.dark-mode .Social-item a,
.dark-mode .nav-link-hover,
.dark-mode .menu-color {
  color: #fff;
  font-weight: 100;
  font-family: AvertLight;
}

.toggle-menu {
  display: block;
  position: relative;
  margin: 2rem;
  width: 3rem;
  height: 3rem;
  text-indent: -999em;
  cursor: pointer;
}

.toggle-menu--clicked .menu__bar:nth-child(1) {
  top: 1.25rem;
  transform: rotate(45deg);
}

.toggle-menu--clicked .menu__bar :nth-child(2) {
  transform: translateX(100%) scale(0);
}

.toggle-menu--clicked .menu__bar :nth-child(3) {
  top: 1.25rem;
  transform: rotate(-45deg);
}

.menu__bar {
  display: block;
  position: absolute;
  left: 0;
  width: 3rem;
  height: 0.5rem;
  transition: top 0.4s ease-out, transform 0.4s ease-out;
  background-color: #222;
}

.menu__bar:nth-child(1) {
  top: 0;
}

.menu__bar:nth-child(2) {
  top: 1.25rem;
}

.menu__bar:nth-child(3) {
  top: 2.5rem;
}

.dark-mode .menuClose:hover > label > div {
  /* background-color: green !important; */
}

.dark-mode .menuOpen:hover > label > div {
  background-color: transparent !important;
}

.light-mode .menuClose:hover > label > div {
  /* background-color: white !important; */
}

.light-mode .menuOpen:hover > label > div,
.light-mode .menuOpen > label > div {
  background-color: transparent !important;
}

.nav-link-wrapper {
  cursor: pointer;
}
@media (hover: hover) {
  .nav-link-wrapper:hover .nav-link-hover-wrapper {
    transform: translateY(-105%) !important;
    /*
    @media only screen and (max-width: 1199px) {
      transform: translateY(0px) !important;
    }
    */
  }

  // .nav-link-wrapper:hover .nav-link-hover::before {
  //   transform: skewY(7deg);
  // }

  .nav-link-wrapper:hover .nav-link-hover::after {
    transform: skewY(0);
  }
}

.nav-link {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.nav-link-hover-wrapper {
  display: inline-block;
  font-style: normal;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.nav-link-hover {
  display: inline-block;
  // color: transparent;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);

  font-family: Averta CY !important;
  text-transform: capitalize;
  font-size: 18px !important;

  &.bold-font {
    font-family: AvertaSemi !important;
  }
}

// .nav-link-hover::before,
.nav-link-hover::after {
  content: attr(data-text);
  display: block;
  position: absolute;
  transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
}

// .light-mode .nav-link-hover::before,
.light-mode .nav-link-hover::after {
  color: #000 !important;
  font-weight: 500;
}

// .dark-mode .nav-link-hover::before,
.dark-mode .nav-link-hover::after {
  color: #fff;
  font-weight: 500 !important;
}
// .dark-mode .nav-link-hover::before {
//   //  top: 5px;
// }

// .nav-link-hover::before {
//   top: 0;
//   transform: skewY(0);
//   transform-origin: right bottom;
// }

.nav-link-hover::after {
  top: 105%;
  transform: skewY(7deg);
  transform-origin: left top;
}

.list-nav {
  gap: 10rem;
}

.first-list li {
  margin-top: 0.8rem;
  .nav-link-hover {
    font-size: inherit !important;
  }
}

#navSticky > div.vertical-center {
  display: flex;
  flex: none !important;
  height: 70%;
  @media only screen and (max-width: 768px) {
    height: auto;
  }
  @media only screen and (max-width: 500px) {
    padding-left: 35px;
    margin-top: 0;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 50px !important;
  }
}

.menu-color {
  color: rgba(0, 0, 0, 0);
  cursor: text;
  font-family: AvertaCY, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 45px !important;
  @media only screen and (max-width: 768px) {
    margin-bottom: 25px !important;
  }
}

.contact-menu {
  font-size: 1rem;
  margin-top: 2rem;
  justify-self: flex-end;
}

/* HamburgerBar related styles start */
.hamburger {
  /* width: 5rem;
  height: 5rem; */
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 300ms ease;
  transition: 0.5s;
}
.hamburger:hover {
  /* background-color: var(--barBgColor); */
}
.light-mode .hamburger {
  --barBgColor: black;
  --barBgColorHover: white;
}
.dark-mode .hamburger {
  --barBgColor: white;
  --barBgColorHover: black;
}
/*
.hamburger__bar-wrapper {
  --barGap: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: var(--barGap);
  width: 100%;
}
.hamburger__bar {
  --barHeight: 2px;
  --transformOrigin: center center;
  --menuOpenAnimationDuration: 750ms;
  --menuOpenAnimationTimingFunc: ease;
  --menuCloseAnimationDuration: 300ms;
  --menuCloseAnimationDelay: 300ms;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--barHeight);
  background-color: var(--barBgColor);
  transform-origin: var(--transformOrigin);
  transform: rotate(0) scaleX(1);
  transition: top var(--menuCloseAnimationDuration)
      var(--menuCloseAnimationDelay),
    transform var(--menuCloseAnimationDuration);
}
.hamburger__bar.top {
  --menuOpenTopVal: -0.4rem;
  --menuOpenLeftVal: 0.35rem;
  --menuOpenRotateVal: 45deg;
  --menuCloseTopVal: calc(calc(var(--barGap) / 2) + calc(var(--barHeight) / 2));
  --menuCloseLeftVal: 0;
  --menuCloseRotateVal: 45deg;
}
.hamburger__bar.bottom {
  --menuOpenTopVal: calc(-0.4rem - var(--barGap) - var(--barHeight));
  --menuOpenLeftVal: -0.35rem;
  --menuOpenRotateVal: -45deg;
  --menuCloseTopVal: calc(
    calc(var(--barGap) / -2) - calc(var(--barHeight) / 2)
  );
  --menuCloseLeftVal: 0;
  --menuCloseRotateVal: -45deg;
}
*/

.hamburger__bar-wrapper {
  // --barGap: 0.25rem;
  // display: flex;
  // flex-direction: column;
  //gap: var(--barGap);
  width: 100%;

  /*css ad cuberto */

  position: relative;
  top: -1px;
  display: inline-block;
  width: 25px;
  line-height: 25px;
  vertical-align: middle;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    right: -20px;
    left: -20px;
    bottom: -20px;
  }
}
.hamburger__bar {
  /*
  --barHeight: 2px;
  --transformOrigin: center center;
  --menuOpenAnimationDuration: 750ms;
  --menuOpenAnimationTimingFunc: ease;
  --menuCloseAnimationDuration: 300ms;
  --menuCloseAnimationDelay: 300ms;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--barHeight);
  background-color: var(--barBgColor);
  transform-origin: var(--transformOrigin);
  transform: rotate(0) scaleX(1);
  transition: top var(--menuCloseAnimationDuration) var(--menuCloseAnimationDelay), transform var(--menuCloseAnimationDuration);
  */
  width: 25px !important;
  height: 2px;
}
.contact-menu {
  .menu-color {
    margin-bottom: 5px !important;
  }
}
.hamburger__bar.top {
  /*
  --menuOpenTopVal: -0.4rem;
  --menuOpenLeftVal: 0.35rem;
  --menuOpenRotateVal: 45deg;
  --menuCloseTopVal: calc(calc(var(--barGap) / 2) + calc(var(--barHeight) / 2));
  --menuCloseLeftVal: 0;
  --menuCloseRotateVal: 45deg;
  */
  /* default css cuberto */
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  margin: 5px 0;
  top: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background: currentColor;
  -webkit-transition: top 0.3s 0.3s, -webkit-transform 0.3s;
  transition: top 0.3s 0.3s, -webkit-transform 0.3s;
  -o-transition: top 0.3s 0.3s, -o-transform 0.3s;
  -moz-transition: top 0.3s 0.3s, transform 0.3s, -moz-transform 0.3s;
  transition: top 0.3s 0.3s, transform 0.3s;
  transition: top 0.3s 0.3s, transform 0.3s, -webkit-transform 0.3s,
    -moz-transform 0.3s, -o-transform 0.3s;
}
.hamburger__bar.bottom {
  /*
  --menuOpenTopVal: calc(-0.4rem - var(--barGap) - var(--barHeight));
  --menuOpenLeftVal: -0.35rem;
  --menuOpenRotateVal: -45deg;
  --menuCloseTopVal: calc(calc(var(--barGap) / -2) - calc(var(--barHeight) / 2));
  --menuCloseLeftVal: 0;
  --menuCloseRotateVal: -45deg;
  */
  /*Default css as cuberto */

  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  margin: 5px 0;
  top: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background: currentColor;
  -webkit-transition: top 0.3s 0.3s, -webkit-transform 0.3s;
  transition: top 0.3s 0.3s, -webkit-transform 0.3s;
  -o-transition: top 0.3s 0.3s, -o-transform 0.3s;
  -moz-transition: top 0.3s 0.3s, transform 0.3s, -moz-transform 0.3s;
  transition: top 0.3s 0.3s, transform 0.3s;
  transition: top 0.3s 0.3s, transform 0.3s, -webkit-transform 0.3s,
    -moz-transform 0.3s, -o-transform 0.3s;
}
.magnetic-area:hover + #stickyElem .hamburger__bar {
  background-color: var(--barBgColorHover);
}
.hamburger.open .hamburger__bar.middle {
  opacity: 0;
}

.hamburger.open .hamburger__bar.top {
  //top: var(--menuCloseTopVal);
  //left: var(--menuCloseLeftVal);
  //transform: rotate(var(--menuCloseRotateVal));
  //animation: menuOpenTopBar var(--menuOpenAnimationDuration) var(--menuOpenAnimationTimingFunc);

  /*
  -webkit-transform:rotate(45deg) translate(5px, 5px);
  -moz-transform: rotate(45deg) translate(5px, 5px);
  -ms-transform: rotate(45deg) translate(5px, 5px);
  -o-transform:rotate(45deg) translate(5px, 5px);
  transform:rotate(45deg) translate(5px, 5px);
  */
  /*default css as cuberto */

  /*css as cuberto */
  -webkit-animation: cb-btn_menu-open-top 0.75s;
  -moz-animation: cb-btn_menu-open-top 0.75s;
  -o-animation: cb-btn_menu-open-top 0.75s;
  animation: cb-btn_menu-open-top 0.75s;
  top: 4px;
  -webkit-transform: rotate(45deg) scaleX(1);
  -moz-transform: rotate(45deg) scaleX(1);
  -ms-transform: rotate(45deg) scaleX(1);
  -o-transform: rotate(45deg) scaleX(1);
  transform: rotate(45deg) scaleX(1);
  transition-duration: 0s, 0s;
}
.hamburger.open .hamburger__bar.bottom {
  //top: var(--menuCloseTopVal);
  //left: var(--menuCloseLeftVal);
  //transform: rotate(var(--menuCloseRotateVal));
  //animation: menuOpenBottomBar var(--menuOpenAnimationDuration) var(--menuOpenAnimationTimingFunc);

  /*
  -webkit-transform:rotate(-45deg) translate(4px, -3px);
  -moz-transform: rotate(-45deg) translate(4px, -3px);
  -ms-transform: rotate(-45deg) translate(4px, -3px);
  -o-transform:rotate(-45deg) translate(4px, -3px);
  transform:rotate(-45deg) translate(4px, -3px);
  */

  -webkit-animation: cb-btn_menu-open-bottom 0.75s;
  -moz-animation: cb-btn_menu-open-bottom 0.75s;
  -o-animation: cb-btn_menu-open-bottom 0.75s;
  animation: cb-btn_menu-open-bottom 0.75s;
  top: -3px;
  -webkit-transform: rotate(-45deg) scaleX(1);
  -moz-transform: rotate(-45deg) scaleX(1);
  -ms-transform: rotate(-45deg) scaleX(1);
  -o-transform: rotate(-45deg) scaleX(1);
  transform: rotate(-45deg) scaleX(1);
  -webkit-transition-duration: 0s, 0s;
  -moz-transition-duration: 0s, 0s;
  -o-transition-duration: 0s, 0s;
  transition-duration: 0s, 0s;
}

/*
@keyframes menuOpenTopBar {
  0% {
    --transformOrigin: left center;
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(1);
  }
  30% {
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(0);
  }
  30.1% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 0;
    transform: rotate(0) scaleX(0);
  }
  31% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(0);
  }
  100%,
  80% {
    --transformOrigin: left center;
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(1);
  }
}
@keyframes menuOpenBottomBar {
  0% {
    --transformOrigin: right center;
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(1);
  }
  50% {
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(0);
  }
  50.1% {
    top: 0;
    left: 0;
    opacity: 0;
    transform: rotate(0) scaleX(0);
  }
  51%,
  55% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(0);
  }
  100% {
    --transformOrigin: right center;
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(1);
  }
}
*/
/* HamburgerBar related styles end */
.magnetic-area:hover + #stickyElem .hamburger__bar {
  background-color: var(--barBgColorHover);
}

/*
.hamburger.open .hamburger__bar.top {
  top: var(--menuCloseTopVal);
  left: var(--menuCloseLeftVal);
  transform: rotate(var(--menuCloseRotateVal));
  animation: menuOpenTopBar var(--menuOpenAnimationDuration)
    var(--menuOpenAnimationTimingFunc);
}
*/
/*
.hamburger.open .hamburger__bar.bottom {
  top: var(--menuCloseTopVal);
  left: var(--menuCloseLeftVal);
  transform: rotate(var(--menuCloseRotateVal));
  animation: menuOpenBottomBar var(--menuOpenAnimationDuration)
    var(--menuOpenAnimationTimingFunc);
}
*/
/*
@keyframes menuOpenTopBar {
  0% {
    --transformOrigin: left center;
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(1);
  }
  30% {
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(0);
  }
  30.1% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 0;
    transform: rotate(0) scaleX(0);
  }
  31% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(0);
  }
  100%,
  80% {
    --transformOrigin: left center;
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(1);
  }
}
*/
/*
@keyframes menuOpenBottomBar {
  0% {
    --transformOrigin: right center;
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(1);
  }
  50% {
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotate(0) scaleX(0);
  }
  50.1% {
    top: 0;
    left: 0;
    opacity: 0;
    transform: rotate(0) scaleX(0);
  }
  51%,
  55% {
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(0);
  }
  100% {
    --transformOrigin: right center;
    top: var(--menuOpenTopVal);
    left: var(--menuOpenLeftVal);
    opacity: 1;
    transform: rotate(var(--menuOpenRotateVal)) scaleX(1);
  }
}
*/
/* HamburgerBar related styles end */

@keyframes nav-open {
  from {
    visibility: hidden;
    opacity: 0;
    transform: translateX(var(--startTranslateX));
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(var(--endTranslateX));
  }
}
@keyframes nav-close {
  from {
    opacity: 1;
    visibility: visible;
    transform: translateX(var(--startTranslateX));
  }
  to {
    visibility: hidden;
    opacity: 0;
    transform: translateX(var(--endTranslateX));
  }
}

/* Max width media queries */
/* Note - Put small width media queries first (applicable for only 'min-width' media queries) */
@media screen and (min-width: 1024px) {
  .list-nav .menu-item {
    margin-top: 0;
  }
}

/* Max width media queries */
/* Note - Put large width media queries first (applicable for only 'max-width' media queries) */
@media screen and (max-width: 992px) {
  .user-icon {
  }

  .dark-mode .logo,
  .light-mode .logo {
    margin-left: 0;
  }

  .list-nav .menu-item {
    font-size: 2.8rem;
  }
  .list-nav .Social-item {
    font-size: 0.85rem;
    margin-top: 0.8rem;
  }

  .contact-menu {
    font-size: 0.9rem;
    margin-top: 0;
  }
}

@media screen and (max-width: 700px) {
  .icons .mobile-icon-menu,
  .icons .mobile-icon-menu .nav-link-hover {
    display: block;
  }
  .icons .search-icon .nav-link-hover-wrapper > span.nav-link-hover,
  .icons .user-icon .nav-link-hover-wrapper > span.nav-link-hover {
    display: none;
  }
  .light-mode .logo {
    font-size: 19px;
  }
  .icons {
    align-items: flex-start;
    position: relative;
    top: 4px;
    width: auto;
  }

  .dark-mode .logo {
    font-size: 19px;
  }

  .icons > div {
    min-width: 40px;
  }

  .first-list {
    display: none;
  }

  .first-list {
    //  display: none !important;
  }

  .list-nav .menu-item {
    font-size: 2.2rem;
    line-height: 1.2;
  }

  /* HamburgerBar */
  .hamburger__bar.top {
    --menuOpenTopVal: -0.35rem;
    --menuOpenLeftVal: 0.25rem;
  }
  .hamburger__bar.bottom {
    --menuOpenTopVal: calc(-0.35rem - var(--barGap) - var(--barHeight));
    --menuOpenLeftVal: -0.25rem;
  }
}
@media screen and (max-width: 420px) {
  .icons {
    width: 105px;
  }
}

@media screen and (max-width: 500px) {
  .contact-menu {
    font-size: 16px;
    margin-top: 1.2rem;
  }
  .list-nav {
    justify-content: start;
  }
}
/*Animation Cuberto */
/*Animation Cuberto */
@-webkit-keyframes cb-btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -webkit-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -webkit-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@-moz-keyframes cb-btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -moz-transform-origin: left center;
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -moz-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -moz-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -moz-transform-origin: left center;
    transform-origin: left center;
  }
}

@-o-keyframes cb-btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -o-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -o-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}

@keyframes cb-btn_menu-open-top {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(1);
    -moz-transform: rotate(0) scaleX(1);
    -o-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  30% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    -moz-transform: rotate(0) scaleX(0);
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  30.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    -moz-transform: rotate(0) scaleX(0);
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  31% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -webkit-transform: rotate(45deg) scaleX(0);
    -moz-transform: rotate(45deg) scaleX(0);
    -o-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
  }

  100%,
  80% {
    opacity: 1;
    top: -5px;
    left: 3px;
    -webkit-transform: rotate(45deg) scaleX(1);
    -moz-transform: rotate(45deg) scaleX(1);
    -o-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}

@-moz-keyframes cb-btn_menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -moz-transform-origin: right center;
    transform-origin: right center;
  }

  50% {
    opacity: 1;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -moz-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -moz-transform: rotate(-45deg) scaleX(0);
    transform: rotate(-45deg) scaleX(0);
  }

  100% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -moz-transform: rotate(-45deg) scaleX(1);
    transform: rotate(-45deg) scaleX(1);
    -moz-transform-origin: right center;
    transform-origin: right center;
  }
}

@-o-keyframes cb-btn_menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -o-transform-origin: right center;
    transform-origin: right center;
  }

  50% {
    opacity: 1;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -o-transform: rotate(-45deg) scaleX(0);
    transform: rotate(-45deg) scaleX(0);
  }

  100% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -o-transform: rotate(-45deg) scaleX(1);
    transform: rotate(-45deg) scaleX(1);
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}

@keyframes cb-btn_menu-open-bottom {
  0% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(1);
    -moz-transform: rotate(0) scaleX(1);
    -o-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }

  50% {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    -moz-transform: rotate(0) scaleX(0);
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  50.1% {
    opacity: 0;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0) scaleX(0);
    -moz-transform: rotate(0) scaleX(0);
    -o-transform: rotate(0) scaleX(0);
    transform: rotate(0) scaleX(0);
  }

  51%,
  55% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -webkit-transform: rotate(-45deg) scaleX(0);
    -moz-transform: rotate(-45deg) scaleX(0);
    -o-transform: rotate(-45deg) scaleX(0);
    transform: rotate(-45deg) scaleX(0);
  }

  100% {
    opacity: 1;
    top: -12px;
    left: -4px;
    -webkit-transform: rotate(-45deg) scaleX(1);
    -moz-transform: rotate(-45deg) scaleX(1);
    -o-transform: rotate(-45deg) scaleX(1);
    transform: rotate(-45deg) scaleX(1);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}
.contact-menu .line-animation-1 {
  text-transform: lowercase;
}

@media screen and (max-width: 1440px) {
  #menuLabelWrapper {
    right: 3% !important;
  }
}
#navBackdropSticky {
  background-color: hsla(0, 0%, 93%, 0.7);
}

.second-list em {
  font-family: Averta CY;
  font-weight: 500 !important;
  .nav-link-hover {
    font-size: inherit !important;
  }
}
.first-list a {
  font-family: Averta CY, sans-serif;
  font-size: 16px !important;
}

@media (min-width: 768px) and (min-height: 550px) {
  .second-list em {
    font-size: 55px;
  }
}

@media (min-width: 1600px) and (min-height: 700px) {
  .second-list em {
    font-size: 55px;
  }
}
@media screen and (max-width: 992px) {
  .second-list em {
    font-size: 2.8rem;
  }
  .logo {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  #navSticky .list-nav {
    justify-content: flex-start;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    .menu-item {
      font-size: 42px !important;
      line-height: 46px;
    }
    .second-list em {
      font-size: 42px !important;
      line-height: 46px;
    }
  }
  #navSticky .contact-menu {
    display: block;
    margin: 0 auto;
    position: static;
    width: 100%;
    width: 90%;
    margin-top: 55px;
  }
  .logo {
    font-size: 19px !important;
  }
}
@media only screen and (max-width: 500px) {
  #navSticky .contact-menu {
    padding-left: 35px;
    margin-top: 25px;
  }
}
@media only screen and (max-width: 400px) {
  #navSticky .list-nav {
    .menu-item {
      font-size: 38px !important;
      line-height: 46px;
    }
    .second-list em {
      font-size: 38px !important;
      line-height: 46px;
    }
  }
}
/*
  #navSticky .list-nav {
    justify-content: flex-start;
    width: 90%;;
    margin-left: auto;
    margin-right: auto;
    .menu-item{
      font-size: 42px !important;
      line-height: 46px;
    }
    .second-list em{
      font-size: 42px !important;
      line-height: 46px;
    }
}

*/
#navSticky {
  .first-list {
    .nav-link-hover {
      font-weight: 300 !important;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .first-list {
      display: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .second-list em {
    font-size: 2.2rem;
    line-height: 1.2;
  }
}
@media (max-width: 450px) {
  #navSticky .list-nav {
    gap: 4rem;
  }
}

@media (min-width: 768px) {
  #navSticky {
    width: 689px;
    padding: 20px !important;
  }
  #navSticky .contact-menu,
  #navSticky .list-nav {
    padding: 0 98px;
  }
}

@media (min-width: 1600px) and (min-height: 700px) {
  #navSticky {
    padding: 50px 0 0 !important;
    width: 880px !important;
  }
}
@media (min-width: 768px) {
  #navSticky .contact-menu,
  #navSticky .list-nav {
    padding: 0 98px;
  }
}

@media (min-width: 1600px) and (min-height: 700px) {
  #navSticky .contact-menu {
    padding: 0 0 84px 98px !important;
  }
}

.dark-ml-1 {
  margin-left: 1.2rem;
}

.contact-menu-link {
  color: rgb(131, 130, 130) !important;
}
.menu-item-style {
  font-weight: 400 !important;
}

.menu-list-item {
  font-weight: 500 !important;
  .light-mode & {
    color: black !important;
  }
}
.light-mode .social-menu-item {
  color: black !important;
}

.menu-color-title {
  font-weight: 500 !important;
  .light-mode & {
    color: black !important;
  }
}

.dark-mode {
}
