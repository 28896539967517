@import "../../styles/mixins/flex";
@import "../../styles/mixins/dimensions";
.dashbord-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.feature-section {
  @include justify-content-between;
}

.dashbord-img {
  @include justify-content-between;
}

.dashbord-img .first-img img {
  @include w-100;
}

.dashbord-img .secend-img img {
//  margin-top: 100px;
  width: 100%;
  width: 85%;
}

.dashbord-img .secend-img img {
  margin-top: 100px;
  width: 85%;
}
.end-secend {
  margin-top: 50px;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .dashbord-img .secend-img img {
    display:flex !important;
    justify-content: flex-end;
    margin: 0 0 0 auto;
    margin-top: 30px;
  }
  .light-mode .btn {
    width: 180px;
  }
}
