.accordion-card{
    width: 100%;
    margin: 0;
    padding: 20px 0;
}
.accordion-card h6{
   font-size: var(--Font-30);
   margin: 40px 0 10px 0;
}
.accordion-card p{
    font-size: 25px;
 }

 
 @media only screen and (max-width:900px) {
    .accordion-card h6{
        font-size: 20px;
        margin: 40px 0 15px 0;
        font-weight: bold;
     }
     .accordion-card p{
        font-size: 20px;
     }
}
 @media only screen and (max-width: 600px) {
    .f-direction{
        flex-direction: column;
    }

    .accordion-card h6{
        margin: 40px 0 5px 0;
     }
}
