.sub-footer {
  margin: 0 auto;
  padding: 20px 0;
  font-size: 12px;
  align-items: center;
  position: relative;
  z-index: 5;
  .toggle-container {
    white-space: nowrap;
    width: auto !important;
    // flex: 0 0 auto !important;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: stretch;
  }
}
.sub-footer ul {
  flex: 1 1 auto;
  gap: 20px 90px;
  flex-wrap: wrap;
  list-style: none;
  justify-content: stretch;
  @media (max-width: 1024px) {
    width: 100%;
    gap: 20px 60px;
    justify-content: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}
.footer-list {
  color: #838282;
}
