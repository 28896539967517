$heading-95: 95px;
$heading-75: 75px;
$font-55: 55px;
$font-50: 50px;
$font-45: 45px;
$font-35: 35px;
$font-30: 30px;
$font-20: 20px;
$font-25: 25px;
$font-15: 15px;
$font-50: 50px;
