@import '../../styles/mixins/flex';
@import '../../styles/mixins/dimensions';

.dashbord-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dashbord-btn {
  margin-top: 30px;
}

.feature-section {
  @include justify-content-between;
}

.dashbord-img {
  @include justify-content-between;

  @media only screen and (max-width: 900px) {
  }
  > div {
    @media only screen and (max-width: 900px) {
    }
  }
  &.fundalysis-image {
    .first-img,
    .secend-img {
      // background-color: #c4c4c4 !important;
    }
  }
}

.dashbord-img ~ div.dashbord-img {
  @media only screen and (max-width: 900px) {
    // margin-top: 100px;
  }
}

.end-secend {
  margin-top: 50px;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .dashbord-img .secend-img img {
    margin-top: 30px;
  }
}
