@mixin container-nav {
  margin: 0 auto;
  
  padding-top: 60px!important;
  width: 90%;
  
}

@mixin video {
  height: 500px;
  width: 100%;
  margin: 0 auto;
}
// Width 100% mixin
@mixin w-100 {
  width: 100%;
}
//width:50%
@mixin w-50 {
  width: 50%;
}
@mixin w-35 {
  width: 30%;
}
@mixin w-45 {
  width: 45%;
}
@mixin w-80 {
  width: 80%;
}
@mixin w-90 {
  width: 90%;
}
@mixin w-70 {
  width: 70%;
}
@mixin w-20 {
  width: 20%;
}
@mixin h-100 {
  height: 100vh;
}

// Overview Content mixin
@mixin overview-content {
  width: 60%;
  margin: 0 auto;
}

@mixin container {
  width: 80vw;
  margin: 0 auto;
}
@mixin slider-arrow {
  justify-content: flex-end;
  margin: 17px auto;
  width: 85%;
}

@mixin Accordion-wrapper {
  padding: 0 !important;
  box-shadow: none !important;
  width: 80%;
  margin: 0 auto;
}

@mixin hero-img-container {
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
}

@mixin admin-inputs {
  min-width: 200px;
  padding: 10px 20px;
  width: 100%;
  height: 100% !important;
  border: 0;
  outline: 0 !important;
}
