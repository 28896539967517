@import "../../styles/mixins/dimensions";
.home-border {
  // border-bottom: 1px solid;
  width: fit-content;
}

.home-text {
  width: 80%;
  @media only screen and (min-width: 1400px) {
    
   
  }
  @media only screen and (max-width: 1200px) {
    //width: 70%;
    
  }
}

.ml-4 {
  margin-right: 40px;
}

@media only screen and (max-width: 900px) {
  .home-text {
    @include w-100;
    line-height: normal;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
}

.home-text {
  font-family: AvertaSemi;
  
  font-size: 20px;
 
  letter-spacing: .00464286em;
  @media (min-width: 1200px){
    
    font-size: 30px !important;
    
    
  }
  .text-slide-up-animation-wrapper-3,.text-slide-up-animation-content-3,.text-slide-up-animation-text-3{
    line-height: 34px;
    @media only screen and (max-width:1024px) {
      line-height: 25px;
      
    }

   
 
    @media only screen and (max-width:600px) {
      line-height: 24px;
      
    }
  }

}
.home-page-container{
  height: 100vh;

  @media only screen and (max-width: 1440px) {
   height: 100vh;
    
  }
  .container {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      justify-content: flex-start;
      margin-top: 120px;
       
     }
     @media only screen and (max-width: 768px) {
      
      margin-top: 90px;
       
     }
    
  }

}

